import {
  AnswerStreakStatus,
  useChallengesEventSubscription,
  useChallengesOverviewLazyQuery,
  useFirstPollOfTheDaySubscription,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { rem, useMantineTheme } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@util/notifications/notifications'
import { AnswerStreakDailyModal } from './AnswerStreakDailyModal'

export function ChallengesNotifications() {
  const [getChallengesData] = useChallengesOverviewLazyQuery({
    variables: {
      lookback: 6,
    },
  })

  const isMobileViewport = useIsMobileViewport()
  const theme = useMantineTheme()

  useChallengesEventSubscription({
    fetchPolicy: 'no-cache',
    onData({ data: challengeData }) {
      const challengeEvent = challengeData?.data?.challengeEvents

      if (!challengeEvent) return

      if (challengeEvent?.__typename === 'AnswerStreakUpdatedEvent') {
        const { status } = challengeEvent

        getChallengesData().then(({ data }) => {
          if (!data?.challengeAnswerStreaks.progress.state) return
          if (
            data.challengeAnswerStreaks.progress.state.__typename !==
            'ProgressTriviaAnswerStreaks'
          )
            return

          switch (status) {
            case AnswerStreakStatus.AnswerStreakStarted: {
              notifications.show({
                title: 'Answer Streak Started',
                message: 'You have started an answer streak',
                icon: '🏃',
                position: 'top-center',
              })
              break
            }
            case AnswerStreakStatus.AnswerStreakIncremented: {
              const currentStreak =
                data.challengeAnswerStreaks.progress.state.overallStreak

              notifications.show({
                title: 'Answer Streak ',
                message: `You're on a ${currentStreak} day streak!`,
                icon: '🔥',
                position: 'top-center',
              })
              break
            }
          }
        })
      }
    },
  })

  useFirstPollOfTheDaySubscription({
    fetchPolicy: 'no-cache',
    onData({ data: pollData }) {
      const pollEvent = pollData?.data?.pollEvents
      if (!pollEvent) return

      getChallengesData().then(({ data }) => {
        if (!data?.challengeAnswerStreaks.progress.state) return
        if (
          data.challengeAnswerStreaks.progress.state.__typename !==
          'ProgressTriviaAnswerStreaks'
        )
          return

        const overallStreak =
          data.challengeAnswerStreaks.progress.state.overallStreak
        const goal = data.challengeAnswerStreaks.progress.state.goal
        const moreThanSevenDaysReward =
          data.challengeAnswerStreaks.progress.state.moreThanSevenDaysReward
        const moreThanFourteenDaysReward =
          data.challengeAnswerStreaks.progress.state.moreThanFourteenDaysReward
        const hasReachedAGoal =
          overallStreak && goal && overallStreak % goal === 0

        const backgroundColor = hasReachedAGoal
          ? theme.colors.swurple[3]
          : theme.colors['deep-blue'][3]

        return modals.open({
          fullScreen: isMobileViewport,
          centered: true,
          withCloseButton: false,
          children: (
            <AnswerStreakDailyModal
              goal={goal || 0}
              hasReachedAGoal={hasReachedAGoal || false}
              streak={overallStreak || 0}
              moreThanSevenDaysReward={moreThanSevenDaysReward || 0}
              moreThanFourteenDaysReward={moreThanFourteenDaysReward || 0}
            />
          ),
          styles: {
            body: {
              height: !isMobileViewport
                ? '80vh'
                : `calc(100vh - ${rem(60)} - var(safe-area-inset-bottom))`,
            },
            header: {
              backgroundColor: backgroundColor,
            },
            content: {
              backgroundColor: backgroundColor,
              color: theme.white,
            },
          },
        })
      })
    },
  })

  return null
}
