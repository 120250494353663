import { ShareQrCodeModal } from '@components/modals/ShareQrCodeModal/ShareQrCodeModal'
import {
  ProfileType,
  PublicVendor,
  Redemption,
  useFeedGetSingleVendorQuery,
  useFollowCommunityMutation,
} from '@graphql'
import useAnalytics from '@hooks/useAnalytics'
import { useBreakpointHeight } from '@hooks/useBreakpoint'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Box,
  Button,
  Group,
  Loader,
  MantineProvider,
  Paper,
  Stack,
  Text,
  colorsTuple,
  createTheme,
  useMantineTheme,
} from '@mantine/core'
import { HeroSection } from '@pages/profile/components/HeroSection'
import { VendorProfileRedemptionsList } from '@pages/profile/components/VendorProfileRedemptionList'
import { useProfileGradient } from '@pages/profile/hooks/useProfileGradient'
import { generateImgixOptions } from '@util/imgixUtils'
import { notifications } from '@util/notifications/notifications'
import { SEMI_BOLD } from '@util/utils'
import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router'
import { QRCodeEntitySharing, ShareSource } from '../../../../constants'
import { FeedCardFooter } from '../FeedCardFooter'
import { FeedCardShowMore } from '../FeedCardShowMore'
import { Overlay } from '../Overlay'

const imgixOpts = generateImgixOptions({
  w: 256,
  h: 256,
  fit: 'crop',
  auto: 'compress',
})

export function VendorSuggestion({
  id,
  skip,
}: {
  id: string
  skip: () => void
}) {
  const { track } = useAnalytics()
  const [followCommunity] = useFollowCommunityMutation()
  const [openQRCodeModal, setOpenQRCodeModal] = useState(false)

  const { data, loading } = useFeedGetSingleVendorQuery({
    variables: {
      id,
      imgixOpts,
    },
  })

  const isFollowing = !!data?.vendor.followRecord

  const [followSubmited, setFollowSubmited] = useState(false)

  const isSmallScreen = useBreakpointHeight('max', 'sm')
  const isMediumScreen = useBreakpointHeight('max', 'md')

  const navigate = useNavigate()
  const theme = useMantineTheme()
  const ref = useRef<HTMLDivElement | null>(null)

  const gradient = useProfileGradient(
    ProfileType.Community,
    data?.vendor.unclaimed,
    data?.vendor.isNonProfit
  )
  const isMobile = useIsMobileViewport()
  const customTheme = createTheme({
    ...theme,
    colors: {
      ...theme.colors,
      white: colorsTuple('#FFFFFF'),
    },
    primaryColor: data?.vendor.isNonProfit ? 'dark' : 'white',
  })

  const vendor = data?.vendor as PublicVendor

  const handleFollow = async () => {
    await followCommunity({
      variables: {
        communityId: id,
      },
      onCompleted: () => {
        notifications.show({
          title: 'Success!',
          message: `You are now following ${vendor.name}`,
        })
        setFollowSubmited(true)
      },
      onError: () => {
        notifications.show({
          title: 'Error',
          message: `Something went wrong while following ${vendor?.name}`,
        })
        setFollowSubmited(false)
      },
    })
    skip()
  }

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" variant="bars" />
      ) : (
        <MantineProvider
          theme={customTheme}
          getRootElement={() => ref.current ?? undefined}
          cssVariablesSelector=":root #vendorProfile"
        >
          <Box w="100%" h="100%" c="white" pos="relative">
            <Overlay
              avatarImageUrl={vendor?.photoUrl}
              vendorDisplayName={vendor?.displayName}
              handleShare={() => {
                track('profile-share-item-opened', {
                  additional_properties: {
                    shareSource: ShareSource.FEED,
                  },
                })
                setOpenQRCodeModal(true)
              }}
            />
            <Stack
              w="100%"
              h="100%"
              gap={0}
              bg={gradient}
              pt={isMobile ? '4vh' : '0'}
              justify="space-between"
            >
              <Stack
                gap={15}
                c={customTheme.primaryColor}
                ref={ref}
                id="vendorProfile"
                pt={15}
                px={20}
              >
                <HeroSection
                  avatarImageUrl={vendor?.photoUrl as string}
                  displayName={vendor.displayName as string}
                  name={vendor.name as string}
                  verified={vendor.verified}
                  isNonProfit={vendor.isNonProfit}
                />

                <VendorProfileRedemptionsList
                  redemptions={
                    vendor.redemptions.slice(
                      0,
                      isSmallScreen ? 1 : isMediumScreen ? 2 : 3
                    ) as Redemption[]
                  }
                  avatarImageUrl={vendor.photoUrl as string}
                  name={vendor.name as string}
                />
                <Button
                  variant="outline"
                  size="sm"
                  radius="xl"
                  onClick={() => navigate(`/profile/${vendor.displayName}`)}
                >
                  See More
                </Button>
              </Stack>
              <Box bottom={0}>
                <FeedCardFooter>
                  <Stack gap={4}>
                    <Text
                      fw={SEMI_BOLD}
                      size={isMobile ? 'lg' : 'xl'}
                      component={Link}
                      to={`/profile/${vendor.displayName}`}
                      className="cursor-pointer hover:underline"
                    >
                      {vendor.name}
                    </Text>
                    <FeedCardShowMore>
                      <Text
                        size={isMobile ? 'md' : 'lg'}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {vendor.bio}
                      </Text>
                    </FeedCardShowMore>
                  </Stack>
                  <Button
                    onClick={handleFollow}
                    fullWidth
                    size={isMobile ? 'compact-md' : 'lg'}
                    disabled={isFollowing || followSubmited}
                    radius="sm"
                    mt={14}
                  >
                    {isFollowing || followSubmited ? 'Followed' : 'Follow'}
                  </Button>
                </FeedCardFooter>
              </Box>
            </Stack>
          </Box>
        </MantineProvider>
      )}
      <ShareQrCodeModal
        title={vendor?.name}
        url={vendor?.referralLink || window.location.href}
        type="Vendor QR Code"
        qrCodeEntitySharing={QRCodeEntitySharing.PROFILE}
        shareSource={ShareSource.FEED}
        open={openQRCodeModal}
        setOpen={setOpenQRCodeModal}
        children={
          <Paper bg="swurple.1" p={8} radius="md">
            <Group align="center" justify="center" wrap="nowrap">
              <Text size="sm" fw={SEMI_BOLD}>
                Share to earn a{' '}
                <SwayCashIcon className="inline-block h-5 w-5" />
                10 bonus!
              </Text>
            </Group>
          </Paper>
        }
      />
    </>
  )
}
