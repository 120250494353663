import { TiltedCard } from '@components/tiltedCard/TiltedCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/sharp-duotone-solid-svg-icons'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Box,
  Button,
  Center,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { getAmountOfSwayCashBonusEarned } from '@pages/leaderboards/utils/leaderboards.utils'
import { BOLD, SEMI_BOLD } from '@util/utils'
import React from 'react'
import ReactConfetti from 'react-confetti'

interface AnswerStreakGoalReachedModalContentProps {
  streak: number
  moreThanSevenDaysReward: number
  moreThanFourteenDaysReward: number
  onClose(): void
}

export function AnswerStreakGoalReachedModalContent({
  streak,
  moreThanSevenDaysReward,
  moreThanFourteenDaysReward,
  onClose,
}: AnswerStreakGoalReachedModalContentProps) {
  const theme = useMantineTheme()

  const amountOfSwayCashBonusEarned =
    getAmountOfSwayCashBonusEarned(
      streak,
      moreThanSevenDaysReward,
      moreThanFourteenDaysReward
    ) || 0

  return (
    <Stack justify="space-between" align="center" h="100%" gap={2}>
      <Box pos="absolute" top={0} left={0} w="100%" h="100%">
        <ReactConfetti
          style={{
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
          }}
          numberOfPieces={200}
        />
      </Box>

      <Center style={{ flexGrow: 1 }}>
        <Stack gap={2}>
          <FontAwesomeIcon
            icon={faFire}
            style={
              {
                color: 'inherit',
                height: 100,
                '--fa-primary-color': theme.colors.white,
                '--fa-secondary-color': theme.colors['deep-blue'][9],
                '--fa-primary-opacity': 1,
                '--fa-secondary-opacity': 1,
              } as React.CSSProperties
            }
          />
          <Stack gap={4}>
            <Stack gap={0}>
              <Text ta="center" fw={BOLD} fz={80}>
                {streak}
              </Text>
              <Text ta="center" fw={BOLD} fz={28}>
                days in a row!
              </Text>
            </Stack>
            <Text ta="center" fw={BOLD}>
              You answered a message every day during the past {streak} days.
              Keep it going!
            </Text>
          </Stack>

          <Group justify="center" my={12}>
            <TiltedCard
              backgroundOne={theme.colors.orange[3]}
              ta="center"
              c="white"
              size="lg"
            >
              You've earned
              <SwayCashIcon className="ml-1 inline-block h-4 w-4" />{' '}
              {(amountOfSwayCashBonusEarned / 100).toFixed(2)} as a bonus on
              your current streak!
            </TiltedCard>
          </Group>
        </Stack>
      </Center>

      <Box px={16} w="100%" fw={SEMI_BOLD}>
        <Button fullWidth color="swurple.9" onClick={onClose}>
          Continue to Feed
        </Button>
      </Box>
    </Stack>
  )
}
